<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent title="서비스 정보 관리" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-16 text-h6"
        >
          전화번호 관리
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="item.callCenterNumber"
            label="고객센터 전화번호"
            placeholder="고객센터 전화번호를 입력해주세요"
            hint="고객센터 전화번호를 입력해주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="item.callCenterNumberForPayments"
            label="결제안내 전화번호"
            placeholder="결제안내 전화번호를 입력해주세요"
            hint="결제안내 전화번호를 입력해주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>

        <v-col
          cols="12"
          class="pt-16 text-h6"
        >
          외부링크 관리
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="item.kakaoChannel"
            label="카카오 고객센터 ID"
            placeholder="카카오 고객센터 ID를 입력해주세요"
            hint="카카오 고객센터 ID를 입력해주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.iosAppUrl"
            label="iOS 다운로드 URL"
            placeholder="iOS 다운로드 URL을 입력해주세요"
            hint="iOS 다운로드 URL을 입력해주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.androidAppUrl"
            label="android 다운로드 URL"
            placeholder="android 다운로드 URL을 입력해주세요"
            hint="android 다운로드 URL을 입력해주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>

        <v-col
          cols="12"
          class="pt-16 text-h6"
        >
          계좌정보
        </v-col>
        <v-col
          cols="4"
          class="text-center"
        >
          <v-text-field
            v-model="bank1.name"
            label="은행1"
            :rules="[rules.required]"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="bank1.account"
            label="계좌1"
            :rules="[rules.required]"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="4"
          class="text-center"
        >
          <v-text-field
            v-model="bank2.name"
            label="은행2"
            :rules="[rules.required]"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="bank2.account"
            label="계좌2"
            :rules="[rules.required]"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="4"
          class="text-center"
        >
          <v-text-field
            v-model="bank3.name"
            label="은행3"
            :rules="[rules.required]"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="bank3.account"
            label="계좌3"
            :rules="[rules.required]"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="pt-16 pb-12"
      >
        <v-col
          cols="auto"
        >
          <v-btn
            class="mx-4"
            color="primary"
            @click="updateItem"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">수정</span>
          </v-btn>
          <v-btn
            color=""
            class="mx-4"
            @click="$router.go(-1)"
          >
            <v-icon small>
              arrow_back
            </v-icon>
            <span class="pl-2">이전 페이지로</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="my-12"
          style="background: #EEE;"
        >
          <p class="text-h5 black--text">
            예상 Result
          </p>
          <p class="black--text">
            @see <a
              :href="`${config.Api.Service.url}/app/info`"
              target="_blank"
            >{{ config.Api.Service.url }}/app/info</a>
          </p>
          <pre
            class="d-block pa-8 black--text"
            style="background: #DDD;"
          >
{
    result: true,
    data: {
        item: {
            callCenterNumber: "{{ item.callCenterNumber }}",
            callCenterNumberForPayments: "{{ item.callCenterNumberForPayments }}",
            kakaoChannel: "{{ item.kakaoChannel }}",
            iosAppUrl: "{{ item.iosAppUrl }}",
            androidAppUrl: "{{ item.androidAppUrl }}",
            bank1: "{{ bank1.name }} | {{ bank1.account }}",
            bank2: "{{ bank2.name }} | {{ bank2.account }}",
            bank3: "{{ bank3.name }} | {{ bank3.account }}",
        }
    }
}</pre>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Config from '../../config'
import PageTitleComponent from '../_elements/title/PageTitle'
import Api from './api/appInfo'

export default {
  components: {
    PageTitleComponent
  },
  data() {
    return {
      config: Config,
      lazy: false,
      valid: false,
      item: {
        callCenterNumber: '',
        callCenterNumberForPayments: '',
        kakaoChannel: '',
        iosAppUrl: '',
        androidAppUrl: '',
        bank1: '',
        bank2: '',
        bank3: ''
      },
      bank1: {
        name: '',
        account: ''
      },
      bank2: {
        name: '',
        account: ''
      },
      bank3: {
        name: '',
        account: ''
      },
      rules: {
        required: (value) => !!value || '꼭 입력해주세요'
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      await this.getItem()
    },
    async getItem() {
      this.item = await Api.getItem()

      const bank1 = this.item.bank1.split(' | ')
      this.bank1.name = bank1[0] || ''
      this.bank1.account = bank1[1] || ''

      const bank2 = this.item.bank2.split(' | ')
      this.bank2.name = bank2[0] || ''
      this.bank2.account = bank2[1] || ''

      const bank3 = this.item.bank3.split(' | ')
      this.bank3.name = bank3[0] || ''
      this.bank3.account = bank3[1] || ''
    },
    async updateItem() {
      this.item.bank1 = `${this.bank1.name} | ${this.bank1.account}`
      this.item.bank2 = `${this.bank2.name} | ${this.bank2.account}`
      this.item.bank3 = `${this.bank3.name} | ${this.bank3.account}`
      const result = await Api.updateItem(this.item)
      if (result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        // this.$router.go(-1)
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
