import { Api } from '@/libraries/api'

async function getItem() {
  const url = `/admin/app_manage/info`
  const result = await Api().get(url)
  const { item } = result.data.data
  return item
}

async function updateItem(item) {
  const url = `/admin/app_manage/info`
  const res = await Api().put(url, item)
  const { result } = res.data
  return result
}

export default {
  getItem,
  updateItem
}
